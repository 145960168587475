import { useEffect, useState, useRef} from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { PUBLIC_KEY, GGP_PAYPAL_CLIENT_ID, API_SERVER_URL } from "../../app/constants";
import { useLocation } from "react-router-dom";
import {  trackEventAthelasHome } from "../../app/utils/logging";
import styled from "styled-components";
import Modal from "react-modal";
import {Contract} from "./Contract"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


function AthelasHomeOnboarding() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [showContractModal, setShowContractModal] = useState(false);
    const [token, setToken] = useState(null);
    const [decodedTokenState, setDecodedTokenState] = useState(null);
    const [allDetails, setAllDetails] = useState(null);
    const [agreeToContract, setAgreeToContract] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [numDevices, setNumDevices] = useState(0);
    const [numStrips, setNumStrips] = useState(0);
    const [patientID, setPatientID] = useState("");
    const [tokenFailed, setTokenFailed] = useState(false);
    const [devicePrice, setDevicePrice] = useState(0);
    const [stripPrice, setStripPrice] = useState(0);
    const [shipping_address, setShippingAddress] = useState('');
    const [orderIDPaypal, setOrderIDPaypal] = useState(false);
    const [errorMessagePaypal, setErrorMessagePaypal] = useState("");
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [paypalButtonHidden, setPaypalButtonHidden] = useState(false);
    const numDevicesRef = useRef(0);
    const devicePriceRef = useRef(0);
    const numStripsRef = useRef(0);
    const stripPriceRef = useRef(0);

  
  
    const modalStyles = {
      content: {
        outerWidth: "30%"
      },
    }

    function checkBlacklist(token) {
      const headers = {};
      axios.get(
        `${API_SERVER_URL}/athelas_one/is_token_blacklisted/${token}`,
        {},
        {
          headers,
        }
        )
        .then((response) => {
          setTokenFailed(false);
        })
        .catch((e) => {
          if (e.response.status === 401 && e.response.data === 'Unauthorized') {
            setTokenFailed(true);
          }
        });
    }

    function fetchAllOnboardingDetails(patientID) {
      const headers = {};
      headers['Authorization'] = 'Bearer ' + token;
      axios.get(
        `${API_SERVER_URL}/ggp_prospective_patient/fetch_ggp_prospective_patient_details/${patientID}`,
        {},
        {
          headers,
        }
        )
        .then((response) => {
          const details = response.data.details
          setAllDetails(details)
          setDevicePrice(details.athelas_home_device_price)
          setStripPrice(details.athelas_home_strip_price)
          setNumStrips(details.num_strips)
          setNumDevices(details.num_devices)
          setShippingAddress(details.shipping_address)  // Add this line
          numDevicesRef.current = details.num_devices;
          devicePriceRef.current = details.athelas_home_device_price;
          numStripsRef.current = details.num_strips;
          stripPriceRef.current = details.athelas_home_strip_price
          console.log(details.shipping_address)
          return
        });
    }

    useEffect(() => {
        Modal.setAppElement('body')
        const tokenFromURL = query.get("token");
        checkBlacklist(tokenFromURL)
        if (!tokenFailed) {
          setToken(tokenFromURL)
          if (decodedTokenState === null) {
            try {
              const decodedToken = jwt.verify(tokenFromURL, PUBLIC_KEY, {
                  algorithms: ["RS256"],
              })
              setDecodedTokenState(decodedToken)
              setPatientID(decodedToken.patient_id)
              setUserEmail(decodedToken.user_email)
              fetchAllOnboardingDetails(decodedToken.patient_id)
            } catch(e) {
              setTokenFailed(true);
            }
          }
          }
    // eslint-disable-next-line
    }, [query, decodedTokenState, tokenFailed]);

    function toggleModal() {
      setShowContractModal(!showContractModal)
    }

    function toggleAgreeCheckBox() {
      setAgreeToContract(!agreeToContract)
    }

    function handleSubmission(paypalTrasactionId) {
      // Check if the shipping address is empty
      if (!shipping_address) {
        console.warn('Shipping address is empty. Fetching onboarding details again...');
        fetchAllOnboardingDetails(patientID);
        return;
      }
    
      setPaypalButtonHidden(false);
      trackEventAthelasHome('click', patientID, 'athelas_home_onboarding', 'agree_to_contract');
    
      const headers = {
        Authorization: 'Bearer ' + token,
      };
    
      axios
        .post(
          `${API_SERVER_URL}/ggp_prospective_patient/complete_onboarding_for_ggp_patient`,
          {
            patient_id: patientID,
            paypal_transaction_id: paypalTrasactionId,
            num_devices: numDevicesRef.current,
            num_strips: numStripsRef.current,
            total_price:
              numDevicesRef.current * devicePriceRef.current +
              numStripsRef.current * stripPriceRef.current,
            shipping_address: shipping_address,
          },
          {
            headers,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert('Success');
            setIsSubmittedSuccessfully(true);
          }
        })
        .catch((e) => {
          alert('There was an issue placing your order. Please contact Athelas Support.');
        });
    }


    const contractModal = () => {
        const header = `Athelas Home Service Agreement`;
        return (
          <div>
            <Modal
              isOpen={showContractModal}
              onAfterOpen={() => {}}
              onRequestClose={toggleModal}
              style={modalStyles}
              contentLabel="Contract Modal"
            >
              <button onClick = {toggleModal} style = {{float: 'right'}}>X</button>
              <h5>{header}</h5>
              <Contract 
                allDetails={allDetails}
              />
            </Modal>
          </div>
        );
      };

      const onError = (data, actions) => {
        setErrorMessagePaypal("An Error occured with during payment. Please contact Athelas Support")
        alert("An Error occured with during the payment transaction. If the error persists, please contact Athelas Support.")
      };

      const buildPaypalPurchaseUnitsPayload = () => {
        
        const user_name = userEmail.substring(0, userEmail.lastIndexOf("@"));
        let all_items = [
          {
            "name": "Athelas Test Strips", /* Shows within upper-right dropdown during payment approval */
            "description": "", /* Item details will also be in the completed paypal.com transaction view */
            "unit_amount": {
              "currency_code": "USD",
              "value":  stripPriceRef.current+ ""
            },
            "quantity": numStripsRef.current + ""
          },

          {
            "name": "Athelas Home Device", /* Shows within upper-right dropdown during payment approval */
            "description": "", /* Item details will also be in the completed paypal.com transaction view */
            "unit_amount": {
              "currency_code": "USD",
              "value": devicePriceRef.current + ""
            },
            "quantity": numDevicesRef.current + ""
          }
        ]
        return [
          {
            "description": `[Athelas Home] Order for ${user_name}`,
            "amount": {
              "currency_code": "USD",
              "value": ((numDevicesRef.current*devicePriceRef.current) + (numStripsRef.current*stripPriceRef.current))+ "",
              "breakdown": {
                "item_total": {  /* Required when including the items array */
                  "currency_code": "USD",
                  "value": ((numDevicesRef.current*devicePriceRef.current) + (numStripsRef.current*stripPriceRef.current))+ ""
                }
              }
            },
            "items": all_items
          }
        ]
      }

      const createOrder = (data, actions) => {
        const purchase_units_load = buildPaypalPurchaseUnitsPayload()
        return actions.order
          .create({
            purchase_units: purchase_units_load,
            // not needed if a shipping address is actually needed
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          })
          .then((orderID) => {
            setOrderIDPaypal(orderID)
            return orderID;
          });
      };

      const onApprove = (data, actions) => {    
        return actions.order.capture().then((details) => {
          handleSubmission(details.purchase_units[0].payments.captures[0].id);
        });
      };
    
      return tokenFailed ? (
        <Center>Your onboarding session has expired.<br />If you have any questions, please contact Athelas Support.</Center>
      ) : isSubmittedSuccessfully ? (
        <Center>Thanks for the payment. We will reach out to you shortly.</Center>
      ) : (
        <Center>
          <h2 style={{textDecoration: 'underline', textDecorationThickness: '2px'}}>Athelas Home Onboarding</h2>
          <p></p>
          <button onClick={toggleModal}>Click To Read Service Agreement</button>
          <p></p>
          <div>{contractModal()}</div>
          <Row>
            <input
                type="checkbox"
                checked={agreeToContract}
                onChange={toggleAgreeCheckBox}
            />
            <p> Check here to indicate you have read and agree to the Service Agreement and the Athelas Terms of Service detailed above.</p>
          </Row>
          <hr></hr>
          <h4 style={{textDecoration: 'underline'}}>First Order Details</h4>
          <table className={`table table-bordered`}>
            <thead className='thead-light'>
              <tr>
                <th></th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Devices</th>
                <td>{numDevices}</td>
                <td>${devicePrice.toLocaleString('en-US')}</td>
                <td>${(numDevices*devicePrice).toLocaleString('en-US')}</td>
              </tr>
              <tr>
                <th scope="row">Strips</th>
                <td>{numStrips}</td>
                <td>${stripPrice.toLocaleString('en-US')}</td>
                <td>${(numStrips*stripPrice).toLocaleString('en-US')}</td>
              </tr>
              <tr>
                <th scope="row">Order Total</th>
                <td>-</td>
                <td>-</td>
                <td>${((numDevices*devicePrice) + (numStrips*stripPrice)).toLocaleString('en-US')}</td>
              </tr>
            </tbody>
          </table>
          <p></p>
          <ShippingAddressSection>
            <h4 style={{textDecoration: 'underline'}}>Shipping Address</h4>
            <AddressContainer>
              {shipping_address ? (
                shipping_address
              ) : (
                "Not Provided"
              )}
            </AddressContainer>
          </ShippingAddressSection>
  
          <p></p>
          <PayPalScriptProvider options={{ "client-id": GGP_PAYPAL_CLIENT_ID, "disable-funding": 'paylater' }}>
            <PayPalButtons style={{ layout: "horizontal" }} disabled={!agreeToContract || showContractModal || paypalButtonHidden} createOrder={createOrder} onApprove={onApprove} onError={onError}/>
          </PayPalScriptProvider>
        </Center>
      );
  }

const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  margin-right: 30%; 
  margin-left: 30%;
  margin-bottom: 5%;
`;

const Row = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ShippingAddressSection = styled.div`
`;

const AddressContainer = styled.div`
  font-size: 14px;
  color: #333;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px
  line-height: 1.5;
`;

export default AthelasHomeOnboarding;